// Generated by Framer (78a4586)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["uVNE8BbJ6", "QWQwbvj1W"];

const serializationHash = "framer-1pf9c"

const variantClassNames = {QWQwbvj1W: "framer-v-qpa2h6", uVNE8BbJ6: "framer-v-11wnx5y"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "uVNE8BbJ6", "Variant 2": "QWQwbvj1W"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, aIQRVoUHE: click ?? props.aIQRVoUHE, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "uVNE8BbJ6"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, aIQRVoUHE, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "uVNE8BbJ6", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapkibvxm = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (aIQRVoUHE) {const res = await aIQRVoUHE(...args);
if (res === false) return false;}
setVariant("QWQwbvj1W")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-11wnx5y", className, classNames)} data-border data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"uVNE8BbJ6"} onTap={onTapkibvxm} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.1)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgba(255, 255, 255, 0.1)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} {...addPropertyOverrides({QWQwbvj1W: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-yjjgri"} layoutDependency={layoutDependency} layoutId={"o3rug_nfg"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 1000, borderBottomRightRadius: 1000, borderTopLeftRadius: 1000, borderTopRightRadius: 1000, rotate: 0}} variants={{QWQwbvj1W: {rotate: -45}}}/><motion.div className={"framer-27us1p"} layoutDependency={layoutDependency} layoutId={"ZQVBYYhMi"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 1000, borderBottomRightRadius: 1000, borderTopLeftRadius: 1000, borderTopRightRadius: 1000}}/><motion.div className={"framer-1lijvkp"} layoutDependency={layoutDependency} layoutId={"ivCFhtaSk"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 1000, borderBottomRightRadius: 1000, borderTopLeftRadius: 1000, borderTopRightRadius: 1000, rotate: 0}} variants={{QWQwbvj1W: {rotate: 45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1pf9c.framer-1h8jsj, .framer-1pf9c .framer-1h8jsj { display: block; }", ".framer-1pf9c.framer-11wnx5y { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; will-change: var(--framer-will-change-override, transform); }", ".framer-1pf9c .framer-yjjgri { flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; top: 7px; width: 24px; }", ".framer-1pf9c .framer-27us1p { flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 2px / 2); width: 24px; }", ".framer-1pf9c .framer-1lijvkp { bottom: 7px; flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; width: 24px; }", ".framer-1pf9c.framer-v-qpa2h6.framer-11wnx5y { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); }", ".framer-1pf9c.framer-v-qpa2h6 .framer-yjjgri { top: calc(50.00000000000002% - 2px / 2); }", ".framer-1pf9c.framer-v-qpa2h6 .framer-27us1p { left: calc(50.00000000000002% - 1px / 2); width: 1px; }", ".framer-1pf9c.framer-v-qpa2h6 .framer-1lijvkp { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }", ".framer-1pf9c[data-border=\"true\"]::after, .framer-1pf9c [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"QWQwbvj1W":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"aIQRVoUHE":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerspYg9zska: React.ComponentType<Props> = withCSS(Component, css, "framer-1pf9c") as typeof Component;
export default FramerspYg9zska;

FramerspYg9zska.displayName = "burger";

FramerspYg9zska.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerspYg9zska, {variant: {options: ["uVNE8BbJ6", "QWQwbvj1W"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, aIQRVoUHE: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerspYg9zska, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})